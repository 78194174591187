import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as variable from "../components/variables"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll-v2"
import "../components/scss/page/home.scss"
import "../components/scss/page/about.scss"
import "../components/scss/page/solutions.scss"
import "../components/scss/page/careers.scss"
import "../components/scss/page/contact.scss"
import "../components/scss/page/phase2.scss"
import "../components/scss/page/phase2new.scss"
import "../components/scss/page/dir.scss"
import "../components/scss/page/podcasts.scss"
import "../components/scss/page/insights.scss"
import "../components/scss/page/microsoft365.scss"
import "../components/scss/page/managed.scss"
import SEO from "../components/seo"
import bgimage from "../images/background2.png"
import topMS from "../images/bg-ms-cloud-solution-partner5.png"
import heroImage from "../images/right_hero-image-_1.png"
import comparisonBG from "../images/pattern.png"
import mdLogo from "../images/md-logo.png"
import pdsLogo from "../images/PDSLogo2.png"
import mdLogoIcon from "../images/md-logo-icon.png"
import pdsLogoIcon from "../images/PDSLogoIcon.png"
import greenCheck from "../images/greencheck2.png"
import redEx from "../images/redex.png"
import midBG from "../images/element_blue-bar-slanted.png"
import bottomBG from "../images/bottom-elements.png"
import carrotLeftPublicURL from "../images/faq-right.png"
import carrotDownPublicURL from "../images/faq-down.png"
import backgroundLights from "../images/lightsfull.png"
import Container from "../components/container"
import loadable from "@loadable/component"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
// Sort and display the different slice options
const PostSlices = ({ slices, blog, leadership, job, podcast, podinfo }) => {
  return slices.map((slice, index) => {
    var sliceID = ""
    if (slice.primary) {
      if (slice.primary.slice_id !== undefined) {
        sliceID = slice.primary.slice_id.text
      }
    }
    const res = (() => {
      switch (slice.slice_type) {
        case "basic_section":
          const BasicSectionSlice = loadable(() =>
            import(`../components/slices/BasicSectionSlice`)
          )
          return (
            <div
              id={"slice-id-" + sliceID}
              key={index}
              className="slice-wrapper slice-basic"
            >
              {<BasicSectionSlice slice={slice} />}
            </div>
          )

        case "hero":
          const HeroSlice = loadable(() =>
            import(`../components/slices/HeroSlice`)
          )
          return (
            <div
              id={"slice-id-" + sliceID}
              key={index}
              className="slice-wrapper slice-hero"
            >
              {<HeroSlice slice={slice} />}
            </div>
          )

        case "block_reference":
          const BlockReferenceSlice = loadable(() =>
            import(`../components/slices/BlockReferenceSlice`)
          )
          return (
            <div
              id={"slice-id-" + sliceID}
              key={index}
              className="slice-wrapper slice-block-reference"
            >
              {<BlockReferenceSlice slice={slice} />}
            </div>
          )

        case "entity_query":
          const EntityQuerySlice = loadable(() =>
            import(`../components/slices/EntityQuerySlice`)
          )
          return (
            <div
              id={"slice-id-" + sliceID}
              key={index}
              className="slice-wrapper slice-entity-query"
            >
              {
                <EntityQuerySlice
                  slice={slice}
                  blog={blog}
                  leadership={leadership}
                  job={job}
                  podcast={podcast}
                  podinfo={podinfo}
                />
              }
            </div>
          )

        // case "slideshow":
        //   return (
        //     <div
        //       id={"slice-id-" + slice.id}
        //       key={index}
        //       className="slice-wrapper slice-slideshow"
        //     >
        //       {/* {<EntityQuerySlice slice={slice} blog={blog} />} */}
        //     </div>
        //   )

        case "columns_section":
          const ColumnSectionSlice = loadable(() =>
            import(`../components/slices/ColumnsSectionSlice`)
          )
          return (
            <div
              id={"slice-id-" + sliceID}
              key={index}
              className="slice-wrapper slice-columns"
            >
              {<ColumnSectionSlice slice={slice} />}
            </div>
          )

        case "left_right_section":
          const LeftRightSlice = loadable(() =>
            import(`../components/slices/LeftRightSlice`)
          )
          return (
            <div
              id={"slice-id-" + sliceID}
              key={index}
              className="slice-wrapper slice-left-right"
            >
              {<LeftRightSlice slice={slice} />}
            </div>
          )

        default:
          return
      }
    })()
    return res
  })
}

const PageStyle = styled.div`
  /* background-image: url(${bgimage}); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #d9e0ea;
  section {
    padding: ${variable.sectionPadding} 0px;
  }
  .background-lights{
    background-image: url(${backgroundLights});
    background-size:contain;
    position:absolute;
    z-index: 0;
    pointer-events: none;
    /* background-repeat:no-repeat; */
    background-position:center;
    /* z-index:-1; */
    width:100%;
    height:100%;
  }
  .ms365-container-inner {
    padding: 0px 0px 0px 0px;
    height:100%;
    position:relative;
    font-family:"Inter" !important;
    *{
      font-family:"Inter" !important;
    }
  }
  .ms365-hero-outer{
    background-image: url(${topMS});
    background-size:100%;
    background-repeat:no-repeat;
    padding-top:135px;
    padding-bottom:60px;
    position:relative;
    z-index:2;
    @media (min-width: 1800px) {
          background-size:70%;
    }
    @media (max-width: 800px) {
          background-size:160%;
    }
  }
  .ms365-hero {
    display: flex;
    align-items: center;
    position:relative;
    align-items:center;
    min-height:680px;
    z-index:1;
    /* padding-top:135px; */
                      @media (max-width: ${variable.mobileWidth}) {

                        min-height:300px;
                      }
    .ms365-hero-left {
      width: 50%;
      @media (max-width: ${variable.mobileWidth}) {
      width:100%;
    }
      h2 {
        margin: 0px;
        line-height: 1;
      }
    }
    .ms365-hero-right {
      width: 50%;
      @media (max-width: ${variable.mobileWidth}) {
      display:none;
    }
      .ms365-hero-right-inner{
        position:absolute;
        top:0;
        right:0;
        width:50%;
        img{
          width:100%;
          height:auto;
        }
      }

    }
    span {
      display: block;
      font-size: 80px;
      font-weight:900;
                            @media (max-width: ${variable.mobileWidth}) {
                              font-size:60px;
                            }
      &.hero-price {
        color: #192d4a;
      }
      &.hero-value {
        color: #ac2330;
        margin-top: 20px;
                                    @media (max-width: ${variable.mobileWidth}) {
margin-top:10px;
                                    }
      }
    }
    .hero-copy {
      max-width: 475px;
      margin-top: 40px;
      font-size:20px;
      line-height:28px;
    }
    .cta-button {
      display: inline-block;
      margin-top: 40px;
      background-color: #ac2330;
      border-radius: 0px;
      padding: 10px 45px;
      font-weight:800;
      font-size:26px;
    }
  }
  .ms365-comparison-outer{
    background-image: url(${midBG});
    background-size:cover;
    background-repeat:no-repeat;
    position:relative;
    z-index:1;
  }
  .ms365-comparison {
    background-color: #fbfcfe;
    padding: 30px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    /* background-image: url(${comparisonBG});
    background-size: cover; */
          @media (max-width: ${variable.mobileWidth}) {
            padding:30px 10px;
          }
    .ms365-comparison-inner {
      background-image: url(${comparisonBG});
      background-color:#F5F5F5;
      background-size: contain;
      padding:60px 20px;
                @media (max-width: ${variable.mobileWidth}) {
            padding:40px 10px;
          }
    }
    .ms365-comparison-header{
      h2{
      width:100%;
      color:#1A2E4B;
      text-align:center;
      font-size:40px;
      line-height:1;
      margin:0px;
      font-size:40px;
      font-weight:800;
      }
      div{
        text-align:center;
        font-size:20px;
        max-width:360px;
        line-height:24px;
        margin:20px auto 0px auto;
      }
    }
    .spacer{
      height:2px;
      background: #D9D9D9;
      box-shadow: 0px 1px 1px #FFFFFF;
      margin-top:45px;
    }
    .compare-logos{
      display:flex;
      margin-top:60px;
      margin-bottom:30px;
      justify-content:space-between;
      padding:0px 15px;
                    @media (max-width: ${variable.mobileWidth}) {
      padding:0px 0px 0px 15px;

              }
      div{
        &:nth-child(1){
                  padding-right:10px;

          width:30%;
                  @media (max-width: ${variable.mobileWidth}) {
          width:70%;
        }
        }
        &:nth-child(2){
          width:40%;
          padding:0px 10px;
              box-sizing: content-box;
                  display: flex;
    justify-content: center;
          img.pds-logo-desktop{
            max-width:223px;
            width:100%;
            height:auto;
          }
          img.pds-logo-mobile{
            display:none;
            max-width:50px;
          }
          @media (max-width: ${variable.mobileWidth}) {
            width:15%;
            img.pds-logo-desktop{
              display:none;
            }
            img.pds-logo-mobile{
              display:block;
            }
          }
        }
        &:nth-child(3){
          width:30%;  
          padding:0px 10px; 
              display: flex;
    justify-content: center;
                  box-sizing: content-box;

          img.md-logo-desktop{
            max-width:150px;
            width:100%;
            height:auto;
          }
          img.md-logo-mobile{
            display:none;
                        max-width:50px;

          }
         @media (max-width: ${variable.mobileWidth}) {
          width:15%;
          img.md-logo-desktop{
            display:none;
          }
          img.md-logo-mobile{
            display:block;
          }
         }  
        }
        text-align:center;
      }
    }
    .compare-item{
      background-color:white;
      padding:50px 15px;
      display:flex;
      justify-content:space-between;
      margin-bottom:20px;
              @media (max-width: ${variable.mobileWidth}) {
      padding:50px 0px 50px 15px;

              }
      .compare-item-1{
        width:30%;
        padding-right:10px;
        @media (max-width: ${variable.mobileWidth}) {
          width:70%;
        }
        h2{
          color:#1A2E4B;
          font-size:40px;
          font-weight:800;
          line-height:48px;
          @media (max-width: ${variable.tabletWidth}) {
            font-size:28px;
          line-height:1.2;
          }
                  @media (max-width: ${variable.mobileWidth}) {
          font-size:20px;
          line-height:1.2;
                    margin:10px 0px;
                  }
        }
        .compare-description{
          color:#A1A9B1;
          font-size:12px;
          line-height:14.5px;
        }

      }
      .compare-item-2{
        width:40%;
        border-left:thin solid #D9D9D9;
        border-right:thin solid #D9D9D9;
        padding:10px 10px;
        box-sizing:content-box;
        color:black;
        font-size:14px;
        line-height:17px;
        @media (max-width: ${variable.mobileWidth}) {
          width:15%;
          display:flex;
          align-items:center;
          justify-content:center;
        }
        .greencheck{
          display:flex;
          justify-content:space-between;
          .check-mobile-copy{
            display:none;
          }
          @media (max-width: ${variable.mobileWidth}) {
            flex-direction:column;
            justify-content:center;
            align-items:center;
            text-align:center;
            .check-mobile-copy{
            display:block;
            font-size:12px;
            }
          }
          .greencheck-container{
            width:20px;
            img{
              width:100%;
              height:auto;
            }
          }
          .green-check-copy{
            width:calc(100% - 35px);
            @media (max-width: ${variable.mobileWidth}) {
              display:none;
            }
          }
        }
      }
      .compare-item-3{
        width:30%;
        color:black;
        font-size:14px;
        line-height:17px;
        padding:0px 10px;
                      box-sizing: content-box;

        @media (max-width: ${variable.mobileWidth}) {
          width:15%;
          display:flex;
          align-items:center;
          justify-content:center;
        }
        .greencheck{
          display:flex;
          justify-content:space-between;
          .check-mobile-copy{
            display:none;
          }
          @media (max-width: ${variable.mobileWidth}) {
            flex-direction:column;
            justify-content:center;
            align-items:center;
            text-align:center;
            .check-mobile-copy{
            display:block;
            font-size:12px;

          }
          }
          .greencheck-container{
            width:20px;
            img{
              width:100%;
              height:auto;
            }
          }
          .green-check-copy{
            width:calc(100% - 35px);
            @media (max-width: ${variable.mobileWidth}) {
      display:none;
    }
          }
        }
        .redex{
          display:flex;
          align-items:center;
          justify-content:center;
          height:100%;
          width:100%;
          .redex-container{
            width:20px;
          }
        }
      }
    }
    
  }
  .ms365-faq{
    padding-top:60px;
    max-width:1000px;
    margin:0 auto;
    position:relative;
    z-index:1;
      h2.faq-header{
        width:100%;
        text-align:center;
        margin-bottom:60px;
        font-size:40px;
        font-weight:800;
        color:#1A2E4B;
      }

      .accordion__button {
                background-image: url(${carrotLeftPublicURL});
                background-repeat: no-repeat;
                background-position-x: calc(100% - 30px);
                background-position-y: center;
                background-size: 80px;
                padding:10px 0px;
                cursor: pointer;
                & > h2 {
                  font-size: 22px;
                  font-weight: 500;
                  color:#170F49;
                  margin:0px 0px 10px 0px;
                  padding:10px 0px;
                  max-width:85%;
                  line-height:28px;
                    @media (max-width: ${variable.mobileWidth}) {
                      max-width:70%;
                    }
                }
              }
              .accordion > div{
                background-color:white;
                box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
                border-radius: 18px;
                border-radius: 14px;
                padding:30px 0px 30px 40px;
                margin-bottom:35px;
                &.accordion-active{
                  border: 2px solid #1A2E4B;
                box-shadow: 0px 6px 16px rgba(74, 58, 255, 0.19);
                }
              }
              .accordion__button[data-accordion-component='AccordionItem'] {
                background-color:white;
              }
              .accordion__button[aria-expanded='true'] {
                background-image: url(${carrotDownPublicURL});
                background-size: 50px;
                background-position-x: calc(100% - 43px);
                background-position-y: calc(100% - 20px);
              }
              .accordion__item{
                border-bottom: thin solid;
                border-color: black;
                padding-bottom: 20px;
                padding-top: 20px;
              }
              .accordion__heading {
                font-size: 1.125rem;
              }
              .accordion__panel {
                line-height:30px;
                color:#6F6C90 !important;
                font-size:18px;
                max-width:80%;
                ul{
                  margin:0px;
                  padding:0px;
                  li{
                    list-style:none;
                    margin-bottom:10px;
                    ul{
                      display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    li{
      display:list-item;
      list-style:disc;
    }
                    }
                  }
                }
                *{
                  color:#6F6C90;
                }
                > div{
                  color:#6F6C90 !important;

                }
              },
    }
    .ms365-contact-outer{
      background-image:url(${bottomBG});
      background-size:cover;
      background-repeat:no-repeat;
      padding-bottom:235px;
      position:relative;
      z-index:1;
    }
    .ms365-contact-container {
    background-color: #fbfcfe;
    padding: 30px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-top:80px;
                    @media (max-width: ${variable.mobileWidth}) {
            padding:30px 10px;
          }
    .ms365-contact-inner {
      background-image: url(${comparisonBG});
      background-color:#F5F5F5;
      background-size: contain;
      padding:60px 20px;
                      @media (max-width: ${variable.mobileWidth}) {
            padding:40px 10px;
          }
      h2{
        width:100%;
        text-align:center;
        color:#1A2E4B;
        font-size:40px;
        font-weight:800;
      }
      .ms365-description{
        text-align:center;
        font-size:28px;
        line-height:34px;
        max-width:700px;
        margin:0 auto;
        margin-bottom:50px;
      }
      #ms365-form{
        ._form_1{
        form._form_1{
          display:block !important;
          background-color:transparent !important;
          max-width:688px;
          margin:0 auto;              
          @media (max-width: ${variable.mobileWidth}) {
            padding:0px;
            margin-top:20px;
          }
        input::placeholder{
          color:transparent !important;

        }
        button._submit{
          background-color:#AC2330 !important;
          width:100%;
          padding:20px 10px !important;
          text-align:center;
          border-radius:0px;
          font-size:26px;
          font-weight:800;
          margin-top:30px;
        }
        textarea{
          height:300px;
          background: #FFFFFF;
          border: 1px solid #D9D9D9;
           border-radius:0px;
           display:block;
        }
        input{
          background: #FFFFFF;
border: 1px solid #D9D9D9;
border-radius:0px;
padding:20px 10px;
        }
        ._form_element{
          &:nth-child(1){
            width:calc(50% - 10px);
            float:left;
            position:relative;
            z-index:99;
          @media (max-width: ${variable.mobileWidth}) {
           float:none;
           width:100%;
          }
            &:after{
              content:"";
              clear:both;
            }
          }
          &:nth-child(2){
            width:calc(50% - 10px);
            float:right;
            position:relative;
            z-index:99;
          @media (max-width: ${variable.mobileWidth}) {
           float:none;
           width:100%;
          }
            &:after{
              content:"";
              clear:both;
            }
          }
          &:nth-child(3){
            width:calc(50% - 10px);
            float:left;
            position:relative;
            z-index:99;
          @media (max-width: ${variable.mobileWidth}) {
           float:none;
           width:100%;
          }
            &:after{
              content:"";
              clear:both;
            }
          }
          &:nth-child(4){
            width:calc(50% - 10px);
            float:right;
            position:relative;
            z-index:99;
          @media (max-width: ${variable.mobileWidth}) {
           float:none;
           width:100%;
          }
            &:after{
              content:"";
              clear:both;
            }
          }
        }
        }
      }
      }
      

    }

  }
`

export const addActive = id => {
  if (typeof window !== "undefined") {
    var script = document.createElement("script")
    script.type = "text/javascript"
    script.src =
      "https://prescriptivesolutions.activehosted.com/f/embed.php?id=" + id
    document.getElementsByTagName("head")[0].appendChild(script)
  }
}
const Page = ({ data }) => {
  //   const prismicContent = data.page.allPas.edges[0]
  //   if (!prismicContent) return null
  const node = data.page
  const leadership = data.leadership
  const podcast = data.podcast
  const job = data.job
  const site = data.site
  const podinfo = data.podinfo
  const blog = data.blog
  //   const site = data.site.allSite_informations.edges[0].node

  return (
    <Layout slug={node.uid}>
      <SEO site={site} page={node} />
      <PageStyle style={{ minHeight: "800px" }}>
        <div className="ms365-container-inner">
          <div className="background-lights"></div>
          <div className="ms365-hero-outer">
            <Container>
              <div className="ms365-hero">
                <div className="ms365-hero-left">
                  <h2>
                    <span className="hero-price">Same Price.</span>
                    <span className="hero-value">More Value.</span>
                  </h2>
                  <div className="hero-copy">
                    Prescriptive offers a wide range of monitoring, management
                    and advanced services and solutions. For customers who
                    partner with us for their Microsoft 365 subscriptions, we
                    provide a number of value-added benefits at no extra cost.
                  </div>
                  <div>
                    <AnchorLink href="#contact-form" className="cta-button">
                      Contact Us
                    </AnchorLink>
                  </div>
                </div>
                <div className="ms365-hero-right">
                  <div className="ms365-hero-right-inner">
                    <img src={heroImage} />
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <div className="ms365-comparison-outer">
            <Container>
              <div className="ms365-comparison">
                <div className="ms365-comparison-inner">
                  <div className="ms365-comparison-header">
                    <h2>Services Comparison</h2>
                    <div>
                      Prescriptive Value Added Services (included at no
                      additional cost)
                    </div>
                  </div>
                  <div className="spacer"></div>
                  <div className="compare-logos">
                    <div></div>
                    <div className="pds-logo">
                      <img className="pds-logo-desktop" src={pdsLogo} />
                      <img className="pds-logo-mobile" src={pdsLogoIcon} />
                    </div>
                    <div className="md-logo">
                      <img className="md-logo-desktop" src={mdLogo} />
                      <img className="md-logo-mobile" src={mdLogoIcon} />
                    </div>
                  </div>
                  <div className="compare-item">
                    <div className="compare-item-1">
                      <h2>Advanced Reporting</h2>
                      <div className="compare-description">
                        Access advanced dashboards that go beyond the standard
                        options for your monthly and quarterly reports.
                      </div>
                    </div>
                    <div className="compare-item-2">
                      <div className="compare-pre greencheck">
                        <div className="greencheck-container">
                          <img src={greenCheck} />
                        </div>
                        <div className="check-mobile-copy">
                          Expert insights & reports
                        </div>
                        <div className="green-check-copy">
                          We provide our clients with dashboards, monthly and
                          quarterly reports that go beyond what comes standard
                          from Microsoft. Our experts review the quarterly
                          reports with [our clients], and provide guidance on
                          usage, spending, security and answer any questions
                          [you] may have about how to get the most out of your
                          Microsoft investment.
                        </div>
                      </div>
                    </div>
                    <div className="compare-item-3">
                      <div className="compare-ms greencheck">
                        <div className="greencheck-container">
                          <img src={greenCheck} />
                        </div>
                        <div className="check-mobile-copy">
                          Standard reports
                        </div>
                        <div className="green-check-copy">
                          Standard reports that come with your Microsoft
                          subcription.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="compare-item">
                    <div className="compare-item-1">
                      <h2>Expert Guidance</h2>
                      <div className="compare-description">
                        A team of experts that can help you navigate the many
                        options available and find the best fit for your
                        organization.
                      </div>
                    </div>
                    <div className="compare-item-2">
                      <div className="compare-pre greencheck">
                        <div className="greencheck-container">
                          <img src={greenCheck} />
                        </div>
                        <div className="green-check-copy">
                          Navigating the array of Microsoft Services and
                          Subscriptions can be challenging. Our team of experts
                          is there to talk you through the options and make sure
                          you make the right choice for your organization. This
                          is available not just when you sign up but whenever
                          you need it.
                        </div>
                      </div>
                    </div>
                    <div className="compare-item-3">
                      <div className="compare-ms redex">
                        <div className="redex-container">
                          <img src={redEx} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="compare-item">
                    <div className="compare-item-1">
                      <h2>Tech Agnostic</h2>
                      <div className="compare-description">
                        We work not only with Microsoft, but with other leading
                        industry solutions to find the right fit for you.
                      </div>
                    </div>
                    <div className="compare-item-2">
                      <div className="compare-pre greencheck">
                        <div className="greencheck-container">
                          <img src={greenCheck} />
                        </div>
                        <div className="green-check-copy">
                          We’re big fans of Microsoft technologies, that’s why
                          we have invested in a partnership with them and
                          recommend their solutions to our clients. Sometimes a
                          Microsoft option isn’t the best fit and we work with
                          many other industry leading solutions.
                        </div>
                      </div>
                    </div>
                    <div className="compare-item-3">
                      <div className="compare-ms redex">
                        <div className="redex-container">
                          <img src={redEx} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="compare-item">
                    <div className="compare-item-1">
                      <h2>Provisioning Options</h2>
                      <div className="compare-description">
                        Provision your own subscription, or let Prescriptive’s
                        experts take care of it for you. The choice is yours.
                      </div>
                    </div>
                    <div className="compare-item-2">
                      <div className="compare-pre greencheck">
                        <div className="greencheck-container">
                          <img src={greenCheck} />
                        </div>

                        <div className="green-check-copy">
                          Our approach gives our clients the option of
                          provisioning their own subscriptions and services or
                          having our team do it for them. Some clients do a
                          little bit of both and we’re good with that.
                        </div>
                      </div>
                    </div>
                    <div className="compare-item-3">
                      <div className="compare-ms redex">
                        <div className="redex-container">
                          <img src={redEx} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="compare-item">
                    <div className="compare-item-1">
                      <h2>Premiere Support </h2>
                      <div className="compare-description">
                        Get fast and responsive support from our team that’s
                        often faster than Microsoft's direct support.
                      </div>
                    </div>
                    <div className="compare-item-2">
                      <div className="compare-pre greencheck">
                        <div className="greencheck-container">
                          <img src={greenCheck} />
                        </div>
                        <div className="check-mobile-copy">
                          Rapid response & premiere support
                        </div>
                        <div className="green-check-copy">
                          As a Microsoft Cloud Services partner we take first
                          call support requests for our clients, and we have
                          Premier support from Microsoft. Premier support is an
                          additional cost option when you buy direct. Our team
                          is also generally easier to get in touch with responds
                          more quickly and is able to provide assistance faster
                          than Microsoft direct. This is by design, Microsoft
                          prefers most customers work through partners.
                        </div>
                      </div>
                    </div>
                    <div className="compare-item-3">
                      <div className="compare-ms greencheck">
                        <div className="greencheck-container">
                          <img src={greenCheck} />
                        </div>
                        <div className="check-mobile-copy">Additional cost</div>
                        <div className="green-check-copy">
                          An additional cost when you buy direct.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="compare-item">
                    <div className="compare-item-1">
                      <h2>White Glove Onboarding</h2>
                      <div className="compare-description">
                        Maximize your investment in Microsoft with our help
                        optimizing your M365 environment for the best
                        experience.
                      </div>
                    </div>
                    <div className="compare-item-2">
                      <div className="compare-pre greencheck">
                        <div className="greencheck-container">
                          <img src={greenCheck} />
                        </div>
                        <div className="green-check-copy">
                          As part of our onboarding process, we will perform an
                          assessment of your M365 environment. This is not a
                          sales tactic, it is to establish a good baseline of
                          your environment and how it is configured. Microsoft
                          frequently recommends certain settings be applied as
                          standard best practice and as much as possible we want
                          to adhere to those best practices.
                        </div>
                      </div>
                    </div>
                    <div className="compare-item-3">
                      <div className="compare-ms redex">
                        <div className="redex-container">
                          <img src={redEx} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <div className="ms365-faq">
            <Container>
              <h2 className="faq-header">Frequently Asked Questions</h2>
              <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                <AccordionItem
                  index={0}
                  uuid={"0"}
                  activeClassName="accordion-active"
                  className="accordion-item"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h2>
                        Do we have access to the same subscriptions, services
                        and contract types?
                      </h2>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div>
                      <ul>
                        <li>
                          Yes. The subscriptions available through a partner are
                          the same as from Microsoft direct. You can buy month
                          to month subscriptions with no commitment, commit to a
                          year and pay up front, monthly or use some
                          combination.
                        </li>
                      </ul>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                  index={1}
                  uuid={"1"}
                  activeClassName="accordion-active"
                  className="accordion-item"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h2>
                        Does it cost more to buy through a partner like
                        Prescriptive than direct from Microsoft?
                      </h2>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div>
                      <ul>
                        <li>No, the price is the same.</li>
                      </ul>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                  index={2}
                  uuid={"2"}
                  activeClassName="accordion-active"
                  className="accordion-item"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h2>
                        Can I still add services and subscriptions myself?
                      </h2>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div>
                      <ul>
                        <li>
                          Yes. You can provision Microsoft Subscriptions and
                          services yourself or opt to have our team do it for
                          you.
                        </li>
                      </ul>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                  index={3}
                  uuid={"3"}
                  activeClassName="accordion-active"
                  className="accordion-item"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h2>How does support work?</h2>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div>
                      <ul>
                        <li>
                          As a Microsoft Certified Cloud Provider, we take first
                          call and escalate to Microsoft if necessary. We
                          actually think this is better, because we’re generally
                          easier to get in touch with, respond more quickly and
                          know more about your environment than a level 1
                          Microsoft support representative. In addition, we have
                          access to Premier Microsoft Support, something that
                          costs extra if you buy direct.
                        </li>
                      </ul>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                  index={4}
                  uuid={"4"}
                  activeClassName="accordion-active"
                  className="accordion-item"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h2>
                        Do we have access to the same subscriptions, services
                        and contract types?
                      </h2>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div>
                      <ul>
                        <li>
                          Yes. The subscriptions available through a partner are
                          the same as from Microsoft direct. You can buy month
                          to month subscriptions with no commitment, commit to a
                          year and pay up front, monthly or use some
                          combination.
                        </li>
                      </ul>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                  index={5}
                  uuid={"5"}
                  activeClassName="accordion-active"
                  className="accordion-item"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h2>
                        We’ve always had an Enterprise Agreement (EA) with
                        Microsoft, why should we buy from PDS instead?
                      </h2>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div>
                      <ul>
                        <li>
                          Microsoft has raised the minimum requirements for EAs
                          to 2,400 users or devices. Organizations that used EAs
                          in the past may no longer qualify.
                        </li>
                        <li>
                          Subscriptions purchased through PDS include Premier
                          Support. Premier Support is an additional cost item on
                          an EA.
                        </li>
                        <li>
                          EAs can be complicated to manage. You must define what
                          services and solutions will be included and in what
                          amounts for the next three years. You can true up
                          additions but never scale down.
                        </li>
                        <li>
                          Buying through PDS provides the greatest flexibility
                          because you can choose your commitment:
                          <ul>
                            <li>
                              Consume licenses as you go with no commitment, or
                            </li>
                            <li>commit to an annual amount, or</li>
                            <li>
                              leverage a combination of the prior two options.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </Container>
          </div>
          <div className="ms365-contact-outer" id="contact-form">
            <Container>
              <div className="ms365-contact-container">
                <div className="ms365-contact-inner">
                  <h2>Didn't find what you were looking for?</h2>
                  <div className="ms365-description">
                    For more information, fill out the form below.
                  </div>
                  <div id="ms365-form">
                    <div className={"_form_1"}>{addActive("1")}</div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>

        {node.data.body && (
          <PostSlices
            slices={node.data.body}
            job={job}
            leadership={leadership}
            podcast={podcast}
            podinfo={podinfo}
            blog={blog}
          />
        )}
      </PageStyle>
    </Layout>
  )
}
export default Page

export const postQuery = graphql`
  query PageBySlugMs365($uid: String!) {
    job: allPrismicJob {
      nodes {
        uid
        data {
          description {
            html
          }
          location {
            text
          }
          teaser_description {
            html
          }
          title {
            text
          }
        }
      }
    }
    podinfo: allPrismicPodcast(
      sort: { fields: first_publication_date, order: DESC }
    ) {
      nodes {
        uid
        data {
          buzzsprout_id {
            text
          }
          title {
            text
          }
          podcast_image {
            gatsbyImageData
          }
        }
      }
    }
    podcast: allBuzzsproutPodcastEpisode(filter: { private: { eq: false } }) {
      nodes {
        title
        slug
        artwork_url
        artist
        description
        summary
        private
      }
    }
    leadership: allPrismicLeadership {
      nodes {
        data {
          bio {
            html
            raw
          }
          linkedin {
            url
          }
          name {
            text
          }
          photo {
            url

            gatsbyImageData
          }
          title {
            text
          }
          twitter {
            url
          }
        }
      }
    }
    page: prismicPa(uid: { eq: $uid }) {
      uid
      id
      type
      data {
        meta_title
        meta_description
        donotindex
        webinar
        typeform_url {
          text
        }
        title {
          text
        }
        body {
          ... on PrismicPaDataBodySlideshow {
            id
            primary {
              background_color
              section_title {
                text
              }
            }
            slice_type
            items {
              image {
                gatsbyImageData
              }
              image_copy {
                html
              }
            }
          }
          ... on PrismicPaDataBodyBasicSection {
            id
            slice_type
            items {
              sidebar_block_reference {
                document {
                  ... on PrismicBlocks {
                    id
                    data {
                      block_title {
                        text
                      }
                      body {
                        ... on PrismicBlocksDataBodyBasicSection {
                          id
                          slice_type
                          primary {
                            background_color
                            background_video {
                              url
                            }
                            youtube_background {
                              embed_url
                            }
                            background_image {
                              gatsbyImageData
                            }
                            content {
                              raw
                            }
                            font_color
                            h1_title
                            section_title {
                              text
                            }
                            slice_id {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            primary {
              section_title {
                text
              }
              h1_title
              font_color
              background_color
              slice_id {
                text
              }
              background_image {
                gatsbyImageData
              }
              background_video {
                url
              }
              youtube_background {
                embed_url
              }
              content {
                html
                raw
              }
            }
          }
          ... on PrismicPaDataBodyEntityQuery {
            id
            slice_type
            primary {
              entity_type
              number_of_entities
              slice_id {
                text
              }
              section_title {
                text
              }
              background_color
              background_image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicPaDataBodyBlockReference {
            id
            primary {
              block_reference {
                document {
                  ... on PrismicBlocks {
                    id
                    data {
                      body {
                        ... on PrismicBlocksDataBodyColumnsSection {
                          id
                          slice_type
                          primary {
                            background_color
                            slice_id {
                              text
                            }
                            background_image {
                              gatsbyImageData
                            }
                            column_count
                            font_color
                            h1_title
                            section_title {
                              text
                            }
                          }
                          items {
                            content {
                              raw
                            }
                          }
                        }
                        ... on PrismicBlocksDataBodyBasicSection {
                          id
                          slice_type
                          primary {
                            background_color
                            background_image {
                              gatsbyImageData
                            }
                          }
                          items {
                            sidebar_block_reference {
                              document {
                                ... on PrismicBlocks {
                                  id
                                  data {
                                    body {
                                      ... on PrismicBlocksDataBodyBasicSection {
                                        id
                                        slice_type
                                        primary {
                                          background_color
                                          background_video {
                                            url
                                          }
                                          youtube_background {
                                            embed_url
                                          }
                                          background_image {
                                            gatsbyImageData
                                          }
                                          content {
                                            html
                                            raw
                                          }
                                          font_color
                                          h1_title
                                          section_title {
                                            text
                                          }
                                          slice_id {
                                            text
                                          }
                                        }
                                      }
                                    }
                                    block_title {
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on PrismicBlocksDataBodyLeftRightSection {
                          id
                          slice_type
                          primary {
                            active_campaign_form_number
                            embed {
                              raw
                            }
                            section_title {
                              text
                            }
                            left_background_image {
                              gatsbyImageData
                            }

                            left_content {
                              html
                              raw
                            }
                            right_background_image {
                              gatsbyImageData
                            }
                            right_content {
                              html
                              raw
                            }
                            right_embed {
                              raw
                            }
                            section_title {
                              text
                            }
                            slice_id {
                              text
                            }
                          }
                        }
                      }
                      block_title {
                        text
                      }
                    }
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicPaDataBodyHero {
            id
            slice_type
            primary {
              background_image {
                gatsbyImageData
              }
              font_color
              min_height
              hero_title {
                text
              }
            }
          }
          ... on PrismicPaDataBodyColumnsSection {
            id
            slice_type
            primary {
              background_color
              column_count
              slice_id {
                text
              }
              background_image {
                gatsbyImageData
              }
              section_title {
                text
              }
              h1_title
              font_color
            }
            items {
              content {
                html
                raw
              }
            }
          }

          ... on PrismicPaDataBodyLeftRightSection {
            id
            slice_type
            primary {
              left_width
              right_width
              slice_id {
                text
              }
              section_title {
                text
              }
              embed {
                text
              }
              right_embed {
                text
              }
              active_campaign_form_number
              right_active_campaign_form_number
              right_content_above_form {
                raw
              }
              left_background_image {
                gatsbyImageData
              }
              right_background_image {
                gatsbyImageData
              }
              right_content {
                html
                raw
              }
              full_width_content {
                raw
              }
              left_content {
                html
                raw
              }
            }
          }
        }
      }
    }
    site: allPrismicSiteInformation {
      nodes {
        data {
          meta_title {
            text
          }
          meta_description {
            text
          }
          description {
            text
          }
          site_url {
            text
          }
          site_title {
            text
          }
          twitter_author {
            text
          }
        }
      }
    }
    blog: allPrismicBlogPost(
      sort: { order: DESC, fields: data___release_date }
    ) {
      nodes {
        uid
        data {
          release_date(formatString: "MMM D Y")
          teaser {
            html
          }
          author {
            text
          }
          title {
            text
          }
          main_image {
            url
            gatsbyImageData
          }
        }
      }
    }
  }
`
